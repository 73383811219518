export class NetworkError extends Error {
    response;
    responseData;
    constructor(message, options) {
        super(message);
        this.response = options.response;
        this.responseData = options.responseData;
    }
}
export async function fetchWithError(input, init) {
    const response = await fetch(input, init);
    if (!response.ok) {
        const responseData = await parseResponse(response);
        throw new NetworkError("Network response was not OK.", {
            response,
            responseData,
        });
    }
    return response;
}
export async function parseResponse(response) {
    if (!response.body) {
        return "";
    }
    const data = await response.text();
    try {
        return JSON.parse(data);
        // eslint-disable-next-line no-empty
    }
    catch (error) { }
    return data;
}

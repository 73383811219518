import Resource from "./resource.js";
export class IdentityProviders extends Resource {
    /**
     * Identity provider
     * https://www.keycloak.org/docs-api/11.0/rest-api/#_identity_providers_resource
     */
    find = this.makeRequest({
        method: "GET",
        path: "/instances",
    });
    create = this.makeRequest({
        method: "POST",
        path: "/instances",
        returnResourceIdInLocationHeader: { field: "id" },
    });
    findOne = this.makeRequest({
        method: "GET",
        path: "/instances/{alias}",
        urlParamKeys: ["alias"],
        catchNotFound: true,
    });
    update = this.makeUpdateRequest({
        method: "PUT",
        path: "/instances/{alias}",
        urlParamKeys: ["alias"],
    });
    del = this.makeRequest({
        method: "DELETE",
        path: "/instances/{alias}",
        urlParamKeys: ["alias"],
    });
    findFactory = this.makeRequest({
        method: "GET",
        path: "/providers/{providerId}",
        urlParamKeys: ["providerId"],
    });
    findMappers = this.makeRequest({
        method: "GET",
        path: "/instances/{alias}/mappers",
        urlParamKeys: ["alias"],
    });
    findOneMapper = this.makeRequest({
        method: "GET",
        path: "/instances/{alias}/mappers/{id}",
        urlParamKeys: ["alias", "id"],
        catchNotFound: true,
    });
    createMapper = this.makeRequest({
        method: "POST",
        path: "/instances/{alias}/mappers",
        urlParamKeys: ["alias"],
        payloadKey: "identityProviderMapper",
        returnResourceIdInLocationHeader: { field: "id" },
    });
    updateMapper = this.makeUpdateRequest({
        method: "PUT",
        path: "/instances/{alias}/mappers/{id}",
        urlParamKeys: ["alias", "id"],
    });
    delMapper = this.makeRequest({
        method: "DELETE",
        path: "/instances/{alias}/mappers/{id}",
        urlParamKeys: ["alias", "id"],
    });
    findMapperTypes = this.makeRequest({
        method: "GET",
        path: "/instances/{alias}/mapper-types",
        urlParamKeys: ["alias"],
    });
    importFromUrl = this.makeRequest({
        method: "POST",
        path: "/import-config",
    });
    updatePermission = this.makeUpdateRequest({
        method: "PUT",
        path: "/instances/{alias}/management/permissions",
        urlParamKeys: ["alias"],
    });
    listPermissions = this.makeRequest({
        method: "GET",
        path: "/instances/{alias}/management/permissions",
        urlParamKeys: ["alias"],
    });
    reloadKeys = this.makeRequest({
        method: "GET",
        path: "/instances/{alias}/reload-keys",
        urlParamKeys: ["alias"],
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}/identity-provider",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}

import { Agent } from "./agent.js";
export default class Resource {
    #agent;
    constructor(client, settings = {}) {
        this.#agent = new Agent({
            client,
            ...settings,
        });
    }
    makeRequest = (args) => {
        return this.#agent.request(args);
    };
    // update request will take three types: query, payload and response
    makeUpdateRequest = (args) => {
        return this.#agent.updateRequest(args);
    };
}

import Resource from "./resource.js";
export class Roles extends Resource {
    /**
     * Realm roles
     */
    find = this.makeRequest({
        method: "GET",
        path: "/roles",
    });
    create = this.makeRequest({
        method: "POST",
        path: "/roles",
        returnResourceIdInLocationHeader: { field: "roleName" },
    });
    /**
     * Roles by name
     */
    findOneByName = this.makeRequest({
        method: "GET",
        path: "/roles/{name}",
        urlParamKeys: ["name"],
        catchNotFound: true,
    });
    updateByName = this.makeUpdateRequest({
        method: "PUT",
        path: "/roles/{name}",
        urlParamKeys: ["name"],
    });
    delByName = this.makeRequest({
        method: "DELETE",
        path: "/roles/{name}",
        urlParamKeys: ["name"],
    });
    findUsersWithRole = this.makeRequest({
        method: "GET",
        path: "/roles/{name}/users",
        urlParamKeys: ["name"],
        catchNotFound: true,
    });
    /**
     * Roles by id
     */
    findOneById = this.makeRequest({
        method: "GET",
        path: "/roles-by-id/{id}",
        urlParamKeys: ["id"],
        catchNotFound: true,
    });
    createComposite = this.makeUpdateRequest({
        method: "POST",
        path: "/roles-by-id/{roleId}/composites",
        urlParamKeys: ["roleId"],
    });
    getCompositeRoles = this.makeRequest({
        method: "GET",
        path: "/roles-by-id/{id}/composites",
        urlParamKeys: ["id"],
    });
    getCompositeRolesForRealm = this.makeRequest({
        method: "GET",
        path: "/roles-by-id/{id}/composites/realm",
        urlParamKeys: ["id"],
    });
    getCompositeRolesForClient = this.makeRequest({
        method: "GET",
        path: "/roles-by-id/{id}/composites/clients/{clientId}",
        urlParamKeys: ["id", "clientId"],
    });
    delCompositeRoles = this.makeUpdateRequest({
        method: "DELETE",
        path: "/roles-by-id/{id}/composites",
        urlParamKeys: ["id"],
    });
    updateById = this.makeUpdateRequest({
        method: "PUT",
        path: "/roles-by-id/{id}",
        urlParamKeys: ["id"],
    });
    delById = this.makeRequest({
        method: "DELETE",
        path: "/roles-by-id/{id}",
        urlParamKeys: ["id"],
    });
    /**
     * Authorization permissions
     */
    updatePermission = this.makeUpdateRequest({
        method: "PUT",
        path: "/roles-by-id/{id}/management/permissions",
        urlParamKeys: ["id"],
    });
    listPermissions = this.makeRequest({
        method: "GET",
        path: "/roles-by-id/{id}/management/permissions",
        urlParamKeys: ["id"],
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}

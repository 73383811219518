import Resource from "./resource.js";
export class UserStorageProvider extends Resource {
    name = this.makeRequest({
        method: "GET",
        path: "/{id}/name",
        urlParamKeys: ["id"],
    });
    removeImportedUsers = this.makeRequest({
        method: "POST",
        path: "/{id}/remove-imported-users",
        urlParamKeys: ["id"],
    });
    sync = this.makeRequest({
        method: "POST",
        path: "/{id}/sync",
        urlParamKeys: ["id"],
        queryParamKeys: ["action"],
    });
    unlinkUsers = this.makeRequest({
        method: "POST",
        path: "/{id}/unlink-users",
        urlParamKeys: ["id"],
    });
    mappersSync = this.makeRequest({
        method: "POST",
        path: "/{parentId}/mappers/{id}/sync",
        urlParamKeys: ["id", "parentId"],
        queryParamKeys: ["direction"],
    });
    constructor(client) {
        super(client, {
            path: "/admin/realms/{realm}/user-storage",
            getUrlParams: () => ({
                realm: client.realmName,
            }),
            getBaseUrl: () => client.baseUrl,
        });
    }
}
